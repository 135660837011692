import React from 'react';

const CareersHealthcare = ({ className }: { className?: string }) => (
  <svg
    className={className}
    width="48"
    height="48"
    viewBox="0 0 48 48"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M29.8464 17.3669C28.8414 17.3721 27.8578 17.0797 27.02 16.527C26.1822 15.9743 25.528 15.1861 25.141 14.2625C24.754 13.3389 24.6514 12.3216 24.8461 11.3398C25.0409 10.358 25.5246 9.45586 26.2352 8.7482C26.9458 8.04055 27.8515 7.55922 28.8374 7.36526C29.8233 7.1713 30.8449 7.2735 31.7724 7.65889C32.6998 8.04428 33.4913 8.69541 34.0463 9.5297C34.6014 10.364 34.895 11.3439 34.8898 12.3446C34.8828 13.6745 34.3491 14.9478 33.4048 15.8881C32.4605 16.8285 31.1818 17.3599 29.8464 17.3669ZM29.8464 8.61752C29.1051 8.61229 28.379 8.82651 27.76 9.23285C27.1411 9.63918 26.657 10.2195 26.3697 10.9001C26.0823 11.5806 26.0047 12.3307 26.1461 13.0555C26.2875 13.7802 26.6418 14.4466 27.1642 14.9705C27.6865 15.4943 28.3534 15.8519 29.0801 15.9978C29.8068 16.1438 30.5604 16.0716 31.2458 15.7904C31.9313 15.5091 32.5173 15.0315 32.9298 14.4181C33.3422 13.8046 33.5626 13.0829 33.5626 12.3446C33.5626 11.3607 33.172 10.4167 32.4758 9.71846C31.7797 9.02026 30.8344 8.6245 29.8464 8.61752Z"
      fill="#475467"
    />
    <path
      d="M28.4145 47.9975H26.5832C26.4072 47.9975 26.2384 47.9278 26.114 47.8039C25.9895 47.6799 25.9196 47.5119 25.9196 47.3366V33.6708H23.5837C23.4165 33.6714 23.2554 33.6093 23.1324 33.4966C23.0093 33.3839 22.9335 33.2289 22.9201 33.0629L21.6726 20.2431C21.6613 20.1054 21.6936 19.9674 21.765 19.8489C21.8364 19.7303 21.9434 19.6372 22.0709 19.5823L24.5658 18.525C24.6434 18.4863 24.7283 18.4647 24.815 18.4614C24.9017 18.458 24.9883 18.4731 25.0687 18.5056C25.1491 18.5381 25.2215 18.5872 25.2813 18.6498C25.341 18.7124 25.3868 18.787 25.4154 18.8686C25.4505 18.9484 25.4695 19.0344 25.4711 19.1216C25.4728 19.2087 25.4569 19.2951 25.4248 19.3761C25.3927 19.4572 25.3451 19.5314 25.2842 19.5939C25.2233 19.6565 25.1507 19.7066 25.0703 19.7411L23.0264 20.6132L24.1676 32.3492H26.5832C26.7592 32.3492 26.9279 32.4189 27.0523 32.5428C27.1768 32.6667 27.2468 32.8348 27.2468 33.01V46.6758H28.3883C28.5643 46.6758 28.733 46.7455 28.8575 46.8694C28.9819 46.9933 29.0519 47.1614 29.0519 47.3366C29.052 47.5074 28.9856 47.6716 28.8669 47.7948C28.7481 47.918 28.5859 47.9906 28.4145 47.9975Z"
      fill="#475467"
    />
    <path
      d="M33.087 48.0008H31.2822C31.1062 48.0008 30.9375 47.9311 30.8131 47.8072C30.6886 47.6832 30.6187 47.5152 30.6187 47.3399C30.6187 47.1647 30.6886 46.9967 30.8131 46.8727C30.9375 46.7488 31.1062 46.6791 31.2822 46.6791H32.4234V33.0133C32.4234 32.8381 32.4934 32.67 32.6179 32.5461C32.7423 32.4222 32.911 32.3525 33.087 32.3525H35.5026L36.6175 20.6166L34.5736 19.7444C34.4201 19.665 34.3004 19.5327 34.237 19.3724C34.1736 19.212 34.1706 19.0343 34.2286 18.8719C34.2632 18.7919 34.3135 18.7196 34.3763 18.659C34.4392 18.5983 34.5133 18.5505 34.5947 18.5186C34.6761 18.4867 34.7632 18.4711 34.8507 18.4728C34.9382 18.4744 35.0242 18.4933 35.1044 18.5283L37.5997 19.5856C37.7271 19.6405 37.8341 19.7336 37.9055 19.8522C37.977 19.9707 38.0093 20.1087 37.9979 20.2465L36.7501 33.0662C36.7367 33.2322 36.6612 33.3872 36.5382 33.4999C36.4151 33.6126 36.2537 33.6747 36.0865 33.6741H33.7506V47.3399C33.7506 47.5152 33.681 47.6832 33.5565 47.8072C33.4321 47.9311 33.263 48.0008 33.087 48.0008Z"
      fill="#475467"
    />
    <path
      d="M21.5 19.1463H17.3659V27H9.96748V19.1463H2V11.8537H9.96748V4H17.3659V11.8537H24"
      stroke="#475467"
    />
  </svg>
);

export default CareersHealthcare;
